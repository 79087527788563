import React from 'react';
import PropTypes from 'prop-types';

import './Logo.scss';

class Logo extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    animate: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    animate: false,
  };

  render() {
    const { className, animate } = this.props;
    return (
      <div className={`Logo ${className} ${animate ? 'animate' : ''}`}>
        <svg width="151px" height="46px" viewBox="0 0 151 46">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              id="logo"
              transform="translate(-125.000000, -50.000000)"
              fill="#F7F7F8"
            >
              <g
                id="Select-Logo-full"
                transform="translate(125.000000, 50.000000)"
              >
                <g transform="translate(-0.000000, 0.000000)">
                  <path
                    id="icon"
                    fillRule="nonzero"
                    d="M18.0036049,31.4265185 L28.0668148,31.4265185 L28.0668148,34.6317531 L18.0036049,34.6317531 L18.0036049,31.4265185 Z M45.9840988,22.9500247 C45.9840988,35.604 35.6891852,45.8989136 23.0408889,45.8989136 C10.3925926,45.8989136 0.0851851852,35.604 0.0851851852,22.9500247 C0.0851851852,10.2960494 10.3823704,0 23.0352099,0 C35.6880494,0 45.9840988,10.2949136 45.9840988,22.9500247 Z M42.78,22.9500247 C42.78,12.0622222 33.9207407,3.20523457 23.0352099,3.20523457 C12.149679,3.20523457 3.29041975,12.0644938 3.29041975,22.9500247 C3.29041975,33.8355556 12.149679,42.693679 23.0352099,42.693679 C33.9207407,42.693679 42.78,33.8366914 42.78,22.9500247 L42.78,22.9500247 Z M24.4118025,18.0660741 C21.884642,17.5152099 20.5886914,17.2233086 20.5886914,16.0216296 C20.5886914,15.0164444 21.6574815,14.4315062 23.2453333,14.4315062 C25.0478519,14.4315062 26.4312593,15.1129877 27.2581235,16.0920494 L29.7784691,14.0328395 C28.4495802,12.3291358 26.4676049,11.224 23.4395556,11.224 C19.4222222,11.224 17.0245432,13.1355556 17.0245432,16.180642 C17.0245432,19.7459259 20.2320494,20.6852346 22.7592099,21.2031605 C25.1898272,21.7222222 26.3562963,22.0141235 26.3562963,23.2771358 C26.3562963,24.5083457 25.2204938,25.0274074 23.569037,25.0274074 C21.6279506,25.0274074 19.8526914,24.4186173 18.7986667,23.0034074 L16.2499259,25.0853333 C17.6424198,27.243358 20.3388148,28.2031111 23.3407407,28.2031111 C27.2297284,28.2031111 29.9181728,26.6129877 29.9181728,23.0511111 C29.9181728,19.4892346 27.1661235,18.6771358 24.4118025,18.0592593 L24.4118025,18.0660741 Z"
                  />
                  <g
                    id="text"
                    fillRule="nonzero"
                    transform="translate(52.701235, 9.200000)"
                  >
                    <path d="M27.1388642,18.4556543 C27.2138272,19.3824691 28.065679,23.0499753 32.3635556,23.0499753 C35.5142716,23.0499753 36.8477037,21.1191111 37.2179753,20.493284 L40.9979259,22.6797037 C39.5213827,24.6809877 36.8102222,27.3864691 31.9569383,27.3864691 C26.4346667,27.3864691 22.4332346,23.6065185 22.4332346,17.4550123 C22.4332346,10.8219259 26.9173827,7.74617284 31.734321,7.74617284 C38.8115062,7.74617284 40.8502716,13.4160988 40.8502716,18.4556543 L27.1388642,18.4556543 Z M35.9583704,15.1947654 C35.8845432,14.2679506 35.2916543,11.3035062 31.8081481,11.3035062 C28.1395062,11.3035062 27.4353086,14.4530864 27.2876543,15.1947654 L35.9583704,15.1947654 Z" />
                    <polygon points="42.4801481 26.8299259 42.4801481 0.668987654 47.000642 0.668987654 47.000642 26.8299259" />
                    <path d="M53.3736296,18.4556543 C53.4474568,19.3824691 54.3004444,23.0499753 58.598321,23.0499753 C61.749037,23.0499753 63.0824691,21.1191111 63.4527407,20.493284 L67.2326914,22.6797037 C65.7561481,24.6809877 63.0449877,27.3864691 58.1905679,27.3864691 C52.6694321,27.3864691 48.668,23.6065185 48.668,17.4550123 C48.668,10.8219259 53.1521481,7.74617284 57.9690864,7.74617284 C65.0462716,7.74617284 67.085037,13.4160988 67.085037,18.4556543 L53.3736296,18.4556543 Z M62.1931358,15.1947654 C62.1193086,14.2679506 61.5264198,11.3035062 58.0429136,11.3035062 C54.3742716,11.3035062 53.6700741,14.4530864 53.5224198,15.1947654 L62.1931358,15.1947654 Z" />
                    <path d="M86.2414815,21.6052346 C84.9443951,25.2738765 81.5733333,27.3864691 77.8672099,27.3864691 C72.415358,27.3864691 68.7149136,23.0874568 68.7149136,17.566321 C68.7149136,12.7493827 71.9758025,7.74617284 78.0898272,7.74617284 C82.9067654,7.74617284 85.3521481,11.2671605 86.0563457,13.0083457 L82.0912593,14.8608395 C81.5733333,13.270716 80.2739753,11.7112593 77.8672099,11.7112593 C74.865284,11.7112593 73.3092346,14.3792593 73.3092346,17.4550123 C73.3092346,20.6784198 74.9765926,23.2362469 77.9785185,23.2362469 C81.1280988,23.2362469 82.1287407,20.493284 82.4626667,19.601679 L86.2414815,21.6052346 Z" />
                    <path d="M86.7968889,8.30271605 L89.5023704,8.30271605 L89.5023704,1.7037037 L93.949037,1.7037037 L93.949037,8.30044444 L97.9141235,8.30044444 L97.9141235,11.4500247 L93.949037,11.4500247 L93.949037,20.6398025 C93.949037,21.344 93.9865185,22.1583704 94.3931358,22.6036049 C94.9121975,23.1590123 95.869679,23.1590123 96.0616296,23.1590123 C96.6861795,23.1607579 97.3084995,23.0844464 97.9141235,22.9318519 L97.9141235,26.68 C97.3212346,26.7538272 96.2467654,26.9764444 94.7270617,26.9764444 C90.7619753,26.9764444 89.5023704,25.5305679 89.5023704,22.4922963 L89.5023704,11.4500247 L86.7968889,11.4500247 L86.7968889,8.30271605 Z" />
                    <path d="M12.7675556,11.3035062 L10.0257284,10.7106173 C7.24641975,10.1177284 5.2451358,9.63614815 5.2451358,7.59851852 C5.2451358,4.81920988 9.02508642,4.41145679 10.137037,4.41145679 C13.4308642,4.41145679 15.2935802,6.04701235 16.3260247,7.38498765 L19.8095309,3.89239506 C16.4112099,0.298716049 12.5131358,0.00227160494 10.4243951,0.00227160494 C3.82765432,0.00227160494 0.0885925926,3.52325926 0.0885925926,8.15392593 C0.0885925926,13.265037 4.23881481,14.6757037 7.57353086,15.4173827 L10.9093827,16.1579259 C14.2032099,16.8996049 15.9489383,17.3811852 15.9489383,19.5301235 C15.9489383,21.7903704 13.7625185,22.9761481 10.9093827,22.9761481 C7.04765432,22.9761481 4.89758025,21.545037 3.60617284,20.0991605 L0.0136296296,23.6928395 C2.56237037,26.4085432 6.47634568,27.4977778 10.131358,27.4977778 C14.7995062,27.4977778 21.247457,25.5328395 21.247457,19.0485432 C21.2485926,13.7863704 16.8757531,12.1928395 12.7675556,11.3035062 Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default Logo;
