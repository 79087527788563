import React from 'react';

import './FAQView.scss';
import FAQS from '../../data/faqs';
import Logo from '../Logo/Logo';

class FAQView extends React.PureComponent {
  render() {
    return (
      <>
        <header>
          <div className="rainbowStrip" />
          <a aria-label="logo" href="/"><Logo className="invert" /></a>
        </header>
        <article className="FAQView">
          <div className="container">
            <h1>FAQs</h1>
            {FAQS.map((qa, index) => (
              <div key={`q${index + 1}`}>
                <h4 className="FAQs-question">{index + 1}. {qa.q}</h4>
                <p
                  className="FAQs-answer"
                  dangerouslySetInnerHTML={{ __html: qa.a }}
                />
              </div>
            ))}

          </div>
        </article>
      </>
    );
  }
}

export default FAQView;
