const faqsData = [
  {
    q: 'Can you confirm specifically that there is no Vitamin E in your vape products?',
    a: `We at Select have focused on safety, quality, and potency for over five years. We have never used Vitamin E Acetate or other common cutting agents like PG or VG in our cartridges. We’re proud to share two items to help provide our customers with ease of mind around Select’s efficacy and standards:
    <br/><br/>
    <a href="https://www.nbcnews.com/health/vaping/tests-show-bootleg-marijuana-vapes-tainted-hydrogen-cyanide-n1059356">Select was featured in an NBC News story</a>, which differentiates the illicit market from the legal market. In the segment, you’ll see that Select was tested and passed Vitamin E Acetate, pesticide and heavy metals testing, but the illicit market cartridges failed. 
    <br/><br/>
    We also encourage customers to review <a href="https://csalabs.com/wp-content/uploads/2019/10/CannaSafe_VitaminEAcetate_Report.pdf">CannaSafe’s Vitamin E Acetate report</a>, which highlight Select’s passing test results for Vitamin E Acetate, potency and pesticides.`,
  },
  {
    q: 'What due diligence do you do to ensure safety in the cartridges you use?',
    a: 'Select tests to the highest standards for potency and pesticides in every state we\'re in, often testing beyond what is required by the state. In fact, we test for pesticides at lower levels of detection internally compared to our third party compliance labs, ensuring that we have higher standards than what is mandated. We test our oil at several stages in the manufacturing process including right before it is filled in our cartridges. Each cartridge is hand-filled to ensure quality, followed by further inspection through four quality control stations. This is to ensure we are meeting quality and safety standards before the product leaves our facility.',
  },
  {
    q: 'What solvent do you use to extract your cannabis distillate?',
    a: 'We use food-grade ethanol for extraction. The ethanol is removed after extraction and is not in our final products.',
  },
  {
    q: 'What are the ingredients you use to clean your cannabis filling machine and or other equipment?',
    a: 'We use ethanol to clean our filling equipment and other machines.',
  },
  {
    q: ' Why did you discontinue the Flavors line?',
    a: `We no longer sell our Flavors line, as we’re seeing states across the country place temporary bans on flavored terpenes. We’re also hearing customers ask more for cannabis products that provide an experience that is as close to the plant as possible.
    <br/><br/>
    Overall, it’s in the best interest of both the company and the consumer that we focus on our cannabis-derived line, so we’ve chosen to discontinue the Flavors line until otherwise noted.`,
  },
  {
    q: 'Has your hardware and oil been tested for heavy metals?',
    a: 'Yes. We have performed accelerated heavy metal leaching tests on every individual component of our hardware and filled product internally. We do test for Heavy Metals that meet California standards, which are currently the most stringent in the U.S.',
  },
  {
    q: 'Can we obtain test results to prove that there is no Vitamin E Acetate in your product?',
    a: `We have been working with testing labs across the country to begin setting standard procedures for Vitamin E Acetate testing. For those labs who are testing, we can confirm that Select products are testing negative for Vitamin E Acetate. We can also confirm that we have never added Vitamin E Acetate to our product in five years as a company.
        <br/><br/>
        We recommend that our customers <a href="https://www.nbcnews.com/health/vaping/tests-show-bootleg-marijuana-vapes-tainted-hydrogen-cyanide-n1059356">review the NBC News story featuring Select cartridges</a> to better understand the dangers of the illicit market, as well as the measures that Select has taken to set standards for the industry around testing. You will see in the story that Select passes all tests for Vitamin E Acetate, heavy metals, potency and pesticides.`,
  },
  {
    q: 'Do you use MCT oil?',
    a: 'After extensive research, we found MCT oil to be the safest carrier oil option for our Select CBD Blends. We want to clarify that when MCT oil is used in the Select line, it is not a cutting agent. In contrast, it is a carrier for, and adds shelf stability to, the essential oils we use in this line. We stand by our product, our testing procedures, and our ingredients, and appreciate our loyal customers for their ongoing support of Select.',
  },
  {
    q: 'Any other notes or thoughts about your product and this vaping safety concern?',
    a: `We want to continue to encourage our customers to always purchase our products through licensed cannabis retailers in order to ensure that the Select product you’ve come to know and love is compliant and tested to the highest standards. 
        <br/><br/>
        We’ve always been pioneers for testing and safety and we also want our customers to know that we are leading the charge as regulations shift across the country. We’re encouraging labs and legislators to increase testing standards and build a better future for the cannabis industry.`,
  },
  {
    q: 'Can you tell me about the mislabeled product in Oregon?',
    a: 'In November, we learned of a mislabeling issue at our facility in Oregon. As a result of this issue, some of our Oregon customers received Select Elite cartridges that contained botanical terpenes in addition to cannabis derived terpenes. We shared this information with the Oregon Liquor Control Commission (OLCC) and we are working internally to ensure this doesn’t happen again. In the meantime, we are offering an inventory exchange to our retail partners who were a part of this mislabeling. At Select, we remain as focused as ever on making high quality innovative products for our consumers. We apologize for this error.',
  },
];

export default faqsData;
