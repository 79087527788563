import React from 'react';
import FAQView from '../components/FAQView';

import '../styles/global.scss';

const FAQPage = () => (
  <FAQView />
);

export default FAQPage;
